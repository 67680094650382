<template>
  <div class="align-center text-center" style="margin-top: 20px">
    <v-card elevation="6" max-width="800" style="margin: auto">
      <v-toolbar flat dense class="blue darken-3" dark>
        <v-toolbar-title>Account Verification</v-toolbar-title>
      </v-toolbar>

      <!--class that defines paddings(left, right, top && bottom-->
      <div class="pl-4 pr-4 pt-2 pb-4">
        <p></p>

        Please check your email for a link to verify your account.

        <p>
          Until you have not verified your account you will not be able to
          login.
        </p>

        <p>
          Once the verification is done click the button below to advance to your device setup.
        </p>
      </div>
      <v-btn elevation="2" @click="advance">
        <v-icon class="mr-1">check</v-icon>
        Advance
      </v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  methods: {
    advance() {
      this.$router.push({
        name: "deviceSetup",
        query: { redirect: "/deviceSetup" },
      });
    },
  },
};
</script>
